
/* DEBUG OUTLINE - comment this out before prod */
* {
  outline: 0px solid red;
}
body {
  margin: 0;
  font-family: 'Karrik Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* COLOUR VARIABLES */
:root {
  --header-colour: #254E70;
  --header-font-colour: #FFFFFF;

  --background-colour: #254E70;
  --header-link-colour: #FAB2EA;
  --link-colour: #7E4E60;
  --content-colour: #F5F5F5;

  --credits-active-tab-colour: "unset";
  --credits-inactive-tab-colour: rgba(0 0 0 / 0.4);

  --debug-text-colour: #2E5C3C;


}

/* GENERAL STYLES */

html {
  font-size: calc(0.6rem + 1vmin); /* variable font size */
}

header, footer {
  background: var(--header-colour);
  color: var(--header-font-colour);
  text-align:center;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  font-size: 1.4em;
  a.active {text-decoration: underline;}
  a {color: var(--header-link-colour); text-decoration: none;}
  a:visited {color: var(--header-link-colour)}
  a:hover {color: var(--header-link-colour)}
  a:active{color: var(--header-link-colour)}
  
}

header {
  justify-content: space-evenly;
  height: 15vh;


  nav {
    a {
      margin: 0 0.5em;
    }
    a:nth-child(1) {
      margin-left: 0;
    }
    a:last-child {
      margin-right: 0;
    }
  }
}

footer {
  height: 10vh;
  flex-direction: column;
  font-size: 1em;
}

footer p {
  margin: 0;
}

main {
  height: calc(100vh - 15vh - 10vh);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-colour);
  position: relative;
  z-index: 4;
  color: white;

  /* Instert background here */

}
.background-image{
  background-image: url('./assets/images/Photo_Credit_Ocean_Imaging_Giant_Kelp_Forest_HopeSpot.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 75vh;
  width: 100vw;
  /* filter: blur(3px); */
  opacity: 0.9;
  z-index: 1;
  filter:brightness(80%);
}

/* a {color: var(--link-colour);}
a:visited {color: var(--link-colour)}
a:hover {color: var(--link-colour)}
a:active{color: var(--link-colour)} */
a {color: var(--header-link-colour);}
a:visited {color: var(--header-link-colour)}
a:hover {color: var(--header-link-colour)}
a:active{color: var(--header-link-colour)}



@font-face {
  font-family: "Karrik Italic";
  font-style: italic;
  font-weight: normal;
  src: local('Karrik-Italic'), url('./assets/fonts/Karrik-Italic.ttf') format('truetype')
}
@font-face {
  font-family: "Karrik Regular";
  font-style: normal;
  font-weight: normal;
  src: local('Karrik-Regular'), url('./assets/fonts/Karrik-Regular.ttf') format('truetype')
}

/* Home Page Styles */
.home, .credits, .contact {
  width: clamp(350px, 50%, 50vw);
  height: clamp(400px,80%, 70vh);
  /* background-color: var(--content-colour); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 4;
  div {
    width: 80%;
    a {
      font-weight: bold;
    }
  }
}
.frosted-glass {
  position: absolute;
  width: clamp(350px, 50%, 50vw);
  height: clamp(400px,80%, 70vh);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 3;
  -webkit-backgrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgb(228 228 228 / 15%);
  border-radius: 5px;
}

.credits {
  justify-content: flex-start
}
.credits-content-container {
  padding: 0 10%;
  overflow: scroll;
  div {
    width: 100%;
  }
}

.tabs {
  width: 100% !important; 
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 7.5vh;
  div {
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
      user-select: none;
      -moz-user-select: none;
      font-size: 1.2em;
    }
  }
  div:first-child {
    border-radius: 5px 0 0 0;
  }
  div:last-child {
    border-radius: 0 5px 0 0;
  }
}